.footer {
  display: flex;
  // position: absolute;
  width: 100%;
  justify-content: space-around;
  margin-top: 6rem;
  bottom: 0;
  background-image: linear-gradient(to bottom, #f5f7ff, #edefeb);
  //   background-color: #f5f7ff;
  //   background-color: #fcd6f4;
  height: 6em;
}

.logo-footer {
  //   margin-top: 0.5em;
  width: 80px;
  height: auto;
}

.second-logo-footer {
  width: 80px;
  height: auto;
}

.footer-text {
  //   padding-top: 8px;
  font-weight: 600;
}

.respect-style-footer {
  display: flex;
  align-items: baseline;
  margin-top: 2.2em;
}
