/* @media (width: 280px- width: 410px)  */
@media (min-width: 220px) and (max-width: 410px) {
  #root {
    display: contents !important;
  }
  .form-style {
    width: 16rem !important;
    margin-top: 11em;
  }

  .logo-login-style {
    width: 10rem !important;
  }

  .logo-form-am {
    width: 100px !important;
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 15px;
    height: 2.5rem !important;
  }

  .container-input-project-style {
    display: block !important;
  }

  .p-project-style {
    text-align: center;
    margin-top: 2rem;
  }

  .input-project-style {
    width: 18rem !important;
  }

  .footer {
    display: inline-grid !important;
    justify-content: center !important;
    margin-top: 2rem !important;
  }

  .respect-style-footer {
    margin-top: 0em !important;
  }
}

@media (min-width: 200px) and (max-width: 429px) {
  .button-calculate {
    max-width: 12rem !important;
  }
  #weight-input {
    margin-left: 5em !important;
  }
}

@media (max-width: 461px) {
  .bottom-button-carbonfootprint {
    display: grid !important;
    margin-bottom: 1em;
  }
}

@media (min-width: 430px) and (max-width: 628px) {
  .button-calculate {
    width: 18rem !important;
  }
  .align-items-center {
    align-items: end !important;
  }
  .button-calculate {
    width: 20rem !important;
  }
  #weight-input {
    margin-left: 7em !important;
  }
}

@media (min-width: 200px) and (max-width: 628px) {
  .container-select-footprint {
    display: block !important;
    justify-content: inherit !important;
  }
  .title-desc-input {
    display: contents !important;
  }

  .title-desc-input {
    justify-content: space-between !important;
  }

  .position-input {
    display: block !important;
    margin-top: 1rem;
  }

  .input-weight {
    display: inline-table !important;
  }

  .weight-input-style {
    margin-top: 1rem;
    text-align: center;
  }

  .global-container-footprint {
    padding: 2rem !important;
  }

  .style-weight-select {
    margin-left: 0rem !important;
  }
}

@media (min-width: 200px) and (max-width: 718px) {
  .table thead {
    display: none;
  }
  .table tr {
    display: block;
    margin-bottom: 40px;
  }
  .table td {
    display: block;
    text-align: center;
  }
  .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}

@media (min-width: 200px) and (max-width: 759px) {
  .hr-style {
    max-width: 15rem !important;
  }

  .input-desc {
    max-width: 12em;
  }

  .project-name {
    margin-left: 0rem !important;
  }
}

@media (min-width: 200px) and (max-width: 760px) {
  .position-select-footprint {
    display: block !important;
  }

  .style-subcategory-select {
    margin-right: -0.05rem !important;
  }

  .style-element-select {
    margin-right: 0rem !important;
  }
}

@media (min-width: 629px) and (max-width: 865px) {
  .container-select-footprint {
    display: grid !important;
    justify-content: inherit !important;
  }

  .title-desc-input {
    justify-content: space-between !important;
  }
}

@media (min-width: 200px) and (max-width: 1115px) {
  .form-central-div {
    flex-direction: column-reverse !important;
    align-items: center;
  }

  .image-infos {
    margin-bottom: 3em !important;
  }
  .global-container-footprint {
    width: 92% !important;
  }
}
