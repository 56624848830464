#cercle {
  border-radius: 76px;
  width: 120px;
  height: 120px;
  border: solid;
  color: #1c7f8a;
  border-width: 10px;
  background: white;
  position: absolute;
}

.text-cercle {
  position: absolute;
  top: 41px;
  left: 13px;
  font-size: 13px;
  color: black;
}
