* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f8f9fa !important;
  margin: 0;
  font-family: "Calibri Light", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .app-style {
  background: -webkit-radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
  background: -o-radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
  background: -moz-radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
  background: radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
} */
h1 {
  font-family: "Calibri Light, Bold, 30", sans-serif;
}
h2 {
  font-family: "Calibri Body, 25", sans-serif;
  color: #1c7f8a;
  font-weight: 700;
}

p {
  font-family: "Calibri Body, 20", sans-serif;
  color: #1c7f8a;
  font-weight: 700;
}

th,
input,
select,
button {
  font-family: " Calibri Light, Bold 15", sans-serif;
}

a {
  color: #222;
  text-decoration: none;
}

.header {
  overflow: auto;
  background-image: linear-gradient(to bottom, #edefeb, #f5f7ff);
  /* background-color: #f5f7ff; */
  /* background-color: #fcd6f4; */
  padding: 1.5em;
}

.logo-position {
  display: flex;
  justify-content: center;
}

.logo-style {
  width: 15rem;
  height: auto;
  align-items: center;
}

.chart-container {
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.inline-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
