.body-page {
  top: 5em;
  //   min-height: 100vh;
  background: -webkit-radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
  background: -o-radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
  background: -moz-radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
  background: radial-gradient(
    ellipse farthest-corner at top center,
    #f09e6f -10%,
    #a0386c 39%,
    #122b70 92%,
    #070c2d 100%
  );
}

.login-button {
  //   background-color: #e84393;
  //   border-color: #e84393;
  background-color: #fcd6f4;
  border-color: #fcd6f4;
}

.login-button:hover {
  background-color: #e84393;
  border-color: #e84393;
  animation: pulse 1s infinite;
  transition: 0.3s;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.btn-primary {
  color: #1c7f8a;
  font-weight: bold;
}

.logo-login-style {
  margin: auto;
  width: 15rem;
  height: auto;
  align-items: center;
}
.form-style {
  //   background-image: linear-gradient(
  //     to bottom,
  //     #e15db4,
  //     #aa4aa1,
  //     #763989,
  //     #43286d,
  //     #0f174f
  //   );

  //   width: 25rem;

  margin-bottom: 8rem;
}

.login-input {
  width: 14em;
  background-color: #ede1e1;
  border: #ede1e1;
  opacity: 0.7;
}

.input-style {
  display: flex;
  justify-content: space-around;
}

.input-container {
  display: flex;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 15px;
  height: 3em;
}

/* Style the form icons */
.icon {
  padding: 14px;
  background: #ede1e1;
  color: #6e6262;
  min-width: 46px;
  height: auto;
  text-align: center;
  border-radius: 3px;
  opacity: 0.3;
  border: hidden;
}

/* Style the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border-radius: 2px;
  border: #ede1e1;
  opacity: 0.7;
  text-align: center;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.checkbox-login {
  width: 15px;
  border-radius: 4px;
  opacity: 0.8;
  margin-top: 5px;
}

.checkbox-login-text {
  color: white;
  opacity: 0.7;
}

.link-forget-text {
  color: white;
  font-style: italic;
  font-weight: 300;
  margin-left: -20px;
  text-decoration: underline;
  cursor: pointer;
}
.link-forget-text:hover {
  font-weight: 600;
  color: white;
}

.hr-login-style {
  color: #ede1e1;
  border: solid;
  border-width: 1.5px;
  margin-bottom: 2em;
}

.checkbox-login-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 1em;
}

.logo-form-am {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}
