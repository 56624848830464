.global-container-footprint {
  text-align: center;
  width: 60%;
  border: 2px solid #1c7f8a;
  //   background-color: white;
  //   margin-left: auto;
  //   margin-right: auto;
  border-radius: 108px;
  padding: 2em;
}

.project-title {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.project-name {
  display: inline;
  width: 12rem;
  padding: 6px;
  margin: 1rem;
  border-radius: 5px;
  text-align: center;
  border: 1.5px solid #ebebeb;
  background-color: white;
  /* color: #1c7f8a; */
  /* font-weight: 700; */
  font-style: italic;
}

// .style-type-category {
//   margin-left: 5.8rem;
// }

.style-type-select {
  margin-left: 3.2rem;
}

.style-country-select {
  margin-left: 1.6rem;
}

// .style-element-select {
//   margin-left: 3rem;
// }

.style-weight-select {
  margin-left: 3.1rem;
}

.style-quantity-select {
  margin-left: 0.7rem;
}

.position-margin {
  margin-right: 1rem;
}

.hr-style {
  width: 25rem;
  margin-left: auto;
  margin-right: auto;
  color: black !important;
  //   margin-top: 3rem;
  margin-bottom: 1rem;
  height: 2px !important;
}

::placeholder {
  color: black;
  //   opacity: 0.5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1c7f8a !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1c7f8a !important;
}

.container-input-project-style {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.p-project-style {
  color: #1c7f8a !important;
  font-weight: 600;
}

.form-central-div {
  display: flex;
  justify-content: space-around;
}

.input-project-style {
  border: none;
  width: 20rem;
  height: 3rem;
  margin: 1rem;
  border-radius: 4px;
  background-color: #ebebeb;
  //   opacity: 0.15;
  color: black;
  text-align: center;
  border-bottom: 2px solid #1c7f8a;
}

.button-project-style {
  border: 2px solid #1c7f8a;
  background-color: #1c7f8a;
  color: white;
  border-radius: 4px;
  width: 16rem;
  height: 3rem;
  margin-top: 2rem;
  font-size: x-large;
  font-weight: bold;
}

.title-desc-input {
  display: flex;
  justify-content: start;
  align-items: baseline;
  //   margin-bottom: 2rem;
}

.image-infos {
  max-width: 100%;
  height: auto;
  /* display: flex; */
  //   margin-left: 61em;
  //   margin-top: -38em;
}

// .container-select-footprint {
//   display: flex;
//   justify-content: space-between;
// }

.position-select-footprint {
  display: flex;
  justify-content: space-between;
}

.position-input {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.input-weight {
  display: contents;
  margin-top: 3em;
  width: 6em;
}

.button-calculate {
  background-color: #1c7f8a;
  border: #1c7f8a;
  border-width: 1px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  width: 14rem;
  height: 2.5rem;
  color: white;
  font-weight: 700;
}

.input-desc {
  width: 18em;
  height: 2em;
  border: none;
  background: #ebebeb;
  border-radius: 4px;
  text-align: center;
  font-style: italic;
}

.trash-action {
  background-color: #1c7f8a !important;
  border: #1c7f8a !important;
  width: 4em;
  height: 2em;
}

.table-style {
  margin: 3em;
}

.bottom-button-carbonfootprint {
  display: flex;
  margin-top: 8em;
  justify-content: space-evenly;
}

.btn-bottom-carbonfootprint {
  width: 12em;
  color: #fff;
  background-color: #1c7f8a;
  border-color: #1c7f8a;
  font-weight: bold;
  margin-bottom: 1em;
}

.btn-bottom-carbonfootprint:hover {
  background-color: #fcd6f4;
  border-color: #fcd6f4;
  color: #fff;
}

.title-style {
  width: 4rem;
}
